
import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import CloseIcon from '@/components/common/CloseButton';
import Button from '@/components/common/Button';
import Dropdown from '@/components/common/Dropdown';
import { usePlayerContext } from '@/contexts/playerContext';
import { 
  postUploadDocumentFile, 
  postCreateDocumentData,
  putUpdateDocumentData,
  deleteDocumentFile,
  deletedDocumentData
 } from '@/service/player.service';
import { MoonLoader } from 'react-spinners';
import { 
  GetPlayerDocumentResponse,
  PostCreateDocumentData,
  GetPlayerInfoRequest,
} from '@/type/api';
import { PlayerDocument } from '@/type/player';
import ToggleInput from '@/components/common/ToggleInput';
import { Store, NOTIFICATION_TYPE  } from 'react-notifications-component';
import DeleteButton from '@/components/common/DeleteButton';
import DeleteDocumentModal from '../DeleteDocumentModal';
import Loader from '../../loaders/Loader';

type OnSuccess = (response: any) => void; // Replace 'any' with the expected response type
type OnError = (error: any) => void; // Replace 'any' with the expected error type

type SuccessfulUploadProps = {
    isEdit: boolean;
    userName: string;
    rowData?: PlayerDocument;
    customerId: string;
    file: File | null;
    statuses: string[];
    selectedStatus: string;
    setSelectedStatus: Dispatch<SetStateAction<string>>;
    handleCancelFile: () => void;
    // handleConfirm: () => void;
    onClose: () => void;
    formatFileSize: (bytes: any) => string;
    documentTypes: string[];
    selectedDocument: string;
    setSelectedDocument: Dispatch<SetStateAction<string>>;
    setIsDocumentModified: Dispatch<SetStateAction<boolean>>
};

export const SuccessfulUpload: React.FC<SuccessfulUploadProps> = ({
    onClose,
    userName,
    isEdit,
    rowData,
    customerId,
    file,
    statuses,
    selectedStatus,
    setSelectedStatus,
    handleCancelFile,
    formatFileSize,
    // handleConfirm,
    documentTypes,
    selectedDocument,
    setSelectedDocument,
    setIsDocumentModified
  }) => {

    const { customerName } = usePlayerContext();

    // const [documentData, setDocumentData] = useState<GetPlayerDocumentResponse>({} as GetPlayerDocumentResponse)

    // Track if status button has been set
    const [hasStatusBeenSet, setHasStatusBeenSet] = useState(false);

    // Track if delete confirmation is sent from DeleteDoc modal -> Triggers delete API call
    const [isDeleteDocument, setIsDeleteDocument] = useState(false);

    const [isDeleteDocModalOpen, setIsDeleteDocModalOpen] = useState(false);
    const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);

    const [note, setNote] = useState<string>('');

    const showNotification = (
      title: string,
      type: NOTIFICATION_TYPE, // Use the specific NOTIFICATION_TYPE instead of string
      message?: string
    ) => {
      Store.addNotification({
        title: title,
        message: message || '',
        type: type, // Type is now of the correct NOTIFICATION_TYPE
        insert: 'top',
        container: 'bottom-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        }
      });
    };

    // This useEffect will set the initial value for the note from rowData when in edit mode
    useEffect(() => {
      if (isEdit && rowData) {
          setNote(rowData.note);
      }
    }, [isEdit, rowData]);


    const handleDeleteFile = async () => {
      // First, check if rowData is defined
      if (!rowData) {
        console.error('No document selected for deletion.');
        return; // Exit the function early if rowData is not available
      }
      
      try {
        // Assuming rowData, customerId, and userName are available in the scope

        // First, call the endpoint to delete document data
        const deleteDataResponse = await deletedDocumentData({
          customerId: customerId,
          loginName: userName,
          documentId: rowData.id,
        });
    
        // Check if the response is successful before proceeding
        if (deleteDataResponse.status !== 200) {
          throw new Error('Failed to delete document data.');
        }
    
        // // If the above call was successful, call the endpoint to delete the document file
        // const deleteFileResponse = await deleteDocumentFile({
        //   customerId: customerId,
        //   loginName: userName,
        //   fileName: rowData.fileName,
        // });
    
        // // Check if the file deletion was successful
        // if (deleteFileResponse.status !== 200) {
        //   throw new Error('Failed to delete document file.');
        // }
    
        // If everything was successful, update the state/UI to reflect the changes
        console.log('Document deleted successfully.');
        showNotification('Document successfully deleted! ✅', 'success');
    
        // Set following state to true so documents table is re-rendered
        setIsDocumentModified(true);
        onClose();
      } catch (error) {
        console.error('Error deleting document:', error);
    
        showNotification('Failed to delete document! ❌', 'danger');
        onClose();
      } 
    };
    
    useEffect(() => {
      if (isDeleteDocument) {
        handleDeleteFile();
      }
    }, [isDeleteDocument]);

    const handleConfirm = async () => {
      const params = {
        customerId: customerId,
        loginName: userName,
      };
      setIsConfirmDisabled(true);
      try {
        let response;
    
        if (isEdit && rowData) {
          // Prepare the body for updating the document
          const updatedBody = {
            id: rowData.id,
            fileName: rowData.fileName,
            documentType: selectedDocument,
            documentStatus: selectedStatus,
            note: note,
            createdOn: rowData.createdOn,
          };
          // Update the document data
          response = await putUpdateDocumentData(params, updatedBody);
          console.log('Document data updated successfully', response);
          showNotification('Document successfully updated! ✅', 'success');

        } else {
          // Prepare the body for creating new document data
          const body = {
            fileName: rowData?.fileName || file?.name || 'New file',
            documentType: selectedDocument,
            documentStatus: selectedStatus,
            note: note,
            createdOn: new Date().toISOString(),
          };
          // Create the document data
          response = await postCreateDocumentData(params, body);
          console.log('Document data created successfully', response);
    
          // If the document data is created successfully and there is a file, upload it
          if (file) {
            const uploadResponse = await postUploadDocumentFile(params, file);
            console.log('File uploaded successfully', uploadResponse);
          }
          if(response.status === 200){
            showNotification('Document successfully uploaded! ✅', 'success');
          } else if(response.status === 403){
            showNotification('You don\'t have permission ❌', 'danger');
          }
        }
    
        // Set following state to true so documents table is re-rendered
        setIsDocumentModified(true);
        onClose();
      } catch (error) {
        // If any error occurs, log it and show an error notification
        console.error('Error during document processing', error);
        showNotification('Failed to process document! ❌', 'danger');
      }
    };
    
    

    const getButtonStyle = (status:string) => {
        if (status === selectedStatus) {
          return "grow shrink basis-0 h-12 px-6 py-3 bg-lime-50 rounded-lg border border-green-700 justify-center items-center gap-4 flex";
        } else {
          return "grow shrink basis-0 h-12 px-6 py-3 bg-zinc-100 rounded-lg justify-center items-center gap-4 flex";
        }
    };

    
    const StatusSelector = () => {

      useEffect(() => {
        if (isEdit && rowData && !hasStatusBeenSet) {
          setSelectedStatus(rowData.documentStatus);
        }
      }, [isEdit, rowData, hasStatusBeenSet]);
      

      return (
        <div className="w-[526px] h-20 flex-col justify-start items-start gap-2 inline-flex">
          <div className="w-[53px] text-black text-base font-normal font-['Inter'] leading-normal tracking-wide">Status</div>
          <div className="self-stretch justify-end items-end gap-5 inline-flex">
            {statuses.map((status) => (
              <div key={status} className={`${getButtonStyle(status)} cursor-pointer`} 
                onClick={() => {
                setSelectedStatus(status);
                setHasStatusBeenSet(true);
              }}>
                <div className="text-center text-stone-800 text-base font-semibold font-['Inter'] leading-normal tracking-wide">{status}</div>
              </div>
            ))}
          </div>
        </div>
      );
    };

    return (
      <div className='flex flex-col mt-5 gap-y-5 relative'>
        {/* Uploaded file section */}
        <div className="w-[526px] h-[120px] p-6 bg-zinc-100 rounded-lg shadow flex-col justify-start items-center gap-8 inline-flex">
            <div className="flex self-stretch p-4 bg-[#F7F7F7] rounded-lg justify-between items-center">
                <div className="justify-start items-start gap-4 flex">
                    <div className="w-10 h-10 bg-stone-800 rounded-lg justify-center items-center flex">
                      <div className="w-[9.73px] h-[9.73px] relative flex-col justify-start items-start flex">
                        <img src="/uploadedFile.png" alt="Uploaded file icon" className="w-full h-full object-cover" />
                      </div>
                    </div>
                    <div className="flex-col justify-start items-start inline-flex overflow-x-hidden">
                        <div className="text-stone-800 text-base font-normal font-['Inter'] leading-normal overflow-x-hidden tracking-wide truncate ...">
                          {isEdit ? rowData?.fileName : file?.name}
                        </div>
                        { !isEdit && <div className="text-neutral-500 text-xs font-medium font-['Inter'] leading-none tracking-tight">{formatFileSize(file?.size || 0)}</div>}
                    </div>
                </div>
                <div className="w-6 h-6 relative self-center">
                  {isEdit ?
                    <DeleteButton onClick={() => setIsDeleteDocModalOpen(true)} color='red'/>
                  :
                    <CloseIcon onClick={handleCancelFile}/>
                  }
                </div>
            </div>
        </div>
        {/* Name and doc type section */}
        <div className='flex flex-row gap-x-5'>
          <div className="w-[253px] h-12 px-6 py-3 bg-neutral-100 rounded-lg justify-start items-start gap-4 inline-flex">
              <div className="grow shrink basis-0 rounded-2xl flex-col justify-center items-start inline-flex overflow-x-hidden">
                  <div className="self-stretch text-zinc-500 text-base font-normal font-['Inter'] leading-normal tracking-wide truncate ...">
                    {isEdit ? rowData?.fileName : file?.name}
                  </div>
              </div>
          </div>
          <Dropdown
            options={documentTypes}
            selectedOption={selectedDocument}
            setSelectedOption={setSelectedDocument}
            isEdit={isEdit}
            rowData={rowData}
          />
        </div>
        {/* Status section */}
        <StatusSelector />
        <ToggleInput
            editable={true}
            placeholder={'Note'}
            onChangeValue={setNote}
            isMultiline={true}
            value={note}
            hasLimit={true}
            className="w-full text-sm placeholder:text-secondary focus:outline-0 relative z-10 bg-transparent self-center break-words"
          />
        {/* Note and upload form */}
        <div className='flex flex-row justify-end gap-x-4 pr-2'>
          <Button onClick={handleCancelFile} variant='bg-muted' className=''>
            Cancel
          </Button>
          <Button variant="bg-beautiful" disabled={isConfirmDisabled} onClick={handleConfirm}>
            
            {isConfirmDisabled?
            <div
              className={'max-h-[24px] w-14 flex  justify-center items-center p-1'}>
              <MoonLoader color="#00A60C" size={24} speedMultiplier={0.6} />
            </div>
            :"Confirm"}
            
          </Button>
        </div>
        <DeleteDocumentModal 
          setIsDeleteDocument={setIsDeleteDocument}
          open={isDeleteDocModalOpen}
          onClose={() => setIsDeleteDocModalOpen(false)}
        />
      </div>
    )
  }